<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('osymquota')" :isNewButton="checkPermission('osymquota_store')" @new-button-click="add"
                    @filter-div-status="datatable.filterStatus = $event">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('osymquota')" :isNewButton="checkPermission('osymquota_store')"
                    @new-button-click="add" @filter-div-status="datatable.filterStatus = $event">
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear"
                              :exportExcel="checkPermission('osymquota_excelexport')" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="4" lg="3">
                        <b-form-group :label="$t('program_name')">
                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                :faculty_code="faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>


                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                :total="datatable.total" :queryParams="datatable.queryParams" :lineNumbers="false"
                @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable">
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id == 0 ? "add" : "edit") }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-12">
                                        <ValidationProvider name="academic_year" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('academic_year')">
                                                <academic-years-selectbox :validate-error="errors[0]"
                                                    v-model="form.academic_year">
                                                </academic-years-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="osym_code" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('osym_code')">
                                                <osym-quota-selectbox :validate-error="errors[0]" v-model="form.osym_code">
                                                </osym-quota-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <!-- <div class="col-12 col-lg-6">
                                        <ValidationProvider name="type" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('exam_type')">
                                                <parameter-selectbox v-model="form.type" :validate-error="errors[0]"
                                                    code="osym_exam_types"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div> -->
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider name="quota" rules="required" v-slot="{ errors }">
                                            <b-form-group :label="$t('quota')">
                                                <b-form-input :validate-error="errors[0]" v-model="form.quota">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider name="type" rules="" v-slot="{ errors }">
                                            <b-form-group :label="$t('quota_type2')">
                                                <parameter-selectbox v-model="form.type" :validate-error="errors[0]"
                                                    code="osym_quota_types"></parameter-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <ValidationProvider name="min_point" v-slot="{ errors }">
                                            <b-form-group :label="$t('min_point')">
                                                <b-form-input :validate-error="errors[0]" v-model="form.min_point">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-lg-12">
                                        <ValidationProvider name="point_type" v-slot="{ errors }">
                                            <b-form-group :label="$t('point_type')">
                                                <parameter-selectbox :validate-error="errors[0]" code="osym_point_types"
                                                    v-model="form.point_type">
                                                </parameter-selectbox>
                                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]">
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store" type="button" variant="primary" class="btn-lg mr-2">
                                {{ $t("save").toUpper() }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import qs from 'qs';
import OsymQuotaService from '@/services/OsymQuotaService';
import CommonModal from '@/components/elements/CommonModal';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AcademicYearsSelectbox from '@/components/interactive-fields/AcademicYearsSelectbox.vue';
import OsymQuotaSelectbox from '@/components/interactive-fields/OsymQuotaSelectbox.vue';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox.vue';

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,
        ValidationProvider,
        ValidationObserver,
        AcademicYearsSelectbox,
        OsymQuotaSelectbox,
        ParameterSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("osymquota"),
        };
    },
    data() {
        return {
            types: [
                { value: "YKS", text: "YKS" },
                { value: "DGS", text: "DGS" },
                { value: "YKS_EK", text: "YKS_EK" },
                { value: "DGS_EK", text: "DGS_EK" },
                { value: "YU", text: "YU" },
            ],
            id: 0,
            faculty_code: null,
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("edit"),
                                class: "ri-edit-box-line",
                                permission: "osymquota_update",
                                callback: (row) => {
                                    this.id = row.id;
                                    this.loadData();
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "osymquota_delete",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("academic_year"),
                        field: "academic_year",
                        sortable: true,
                    },
                    {
                        label: this.$t("osym_code"),
                        field: "osym_code",
                        sortable: true,
                    },
                    {
                        label: this.$t("program"),
                        field: this.getLocaleField("program_name"),
                        sortable: false,
                    },
                    {
                        label: this.$t("type"),
                        field: "type",
                        sortable: true,
                    },
                    {
                        label: this.$t("quota"),
                        field: "quota",
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false }),
            };

            return OsymQuotaService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;

                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            };

            return OsymQuotaService.exportExcel(config)
                .then((response) => {
                    this._downloadFile(response, 'osym-quotas.xlsx')
                })
                .catch(e => {
                });
        },
        loadData() {
            if (this.id > 0) {
                OsymQuotaService.get(this.id)
                    .then((response) => {
                        this.$refs.storeForm.reset();
                        this.form = response.data.data;
                        this.$refs.modal.$refs.commonModal.show();
                    })
                    .catch((error) => {
                        if (error.data.message) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            }
        },
        async store() {
            const isValid = await this.$refs.storeForm.validate();
            if (isValid) {
                if (this.id == 0) {
                    OsymQuotaService.store(this.form, this.value)
                        .then((response) => {
                            this.$toast.success(this.$t("api." + response.data.message));

                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                } else {

                    OsymQuotaService.update(this.id, this.form)
                        .then((response) => {

                            this.$toast.success(this.$t("api." + response.data.message));
                        })
                        .catch((error) => {
                            this.showErrors(error)
                        })
                }
            }
        },
        showErrors(error) {
            if (error.status == 422) {

                if (error.data.errors.osym_code) {
                    this.$refs.storeForm.errors.osym_code.push(error.data.errors.osym_code[0]);
                }

                if (error.data.errors.academic_year) {
                    this.$refs.storeForm.errors.academic_year.push(error.data.errors.academic_year[0]);
                }
                if (error.data.errors.type) {
                    this.$refs.storeForm.errors.type.push(error.data.errors.type[0]);
                }
                if (error.data.errors.quota) {
                    this.$refs.storeForm.errors.quota.push(error.data.errors.quota[0]);
                }
                if (error.data.errors.point_type) {
                    this.$refs.storeForm.errors.point_type.push(error.data.errors.point_type[0]);
                }
                if (error.data.errors.min_point) {
                    this.$refs.storeForm.errors.min_point.push(error.data.errors.min_point[0]);
                }
            }
            else if (error.status == 406) {
                this.$toast.error(this.$t('api.' + error.data.message));
            }
        },
        delete(id) {
            this.deleteModal(() => {
                OsymQuotaService.deleteQuoto(id)
                    .then((response) => {
                        this.filter();
                        this.$toast.success(this.$t("api." + response.data.message));
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t("api." + error.data.message));
                    });
            });
        },
        add() {
            this.id = 0;
            this.$refs.storeForm.reset();
            this.form = {};
            this.$refs.modal.$refs.commonModal.show();
        },
    },
};
</script>

